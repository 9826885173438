import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import LoadingLine from 'components/common/loading-line';
import Map, { Marker } from 'components/common/map';
import Input from 'components/common/input';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';

const vehicleIcon = (status, batteryPercent = false) => {
  return { url: `https://storage.googleapis.com/flamingo-static/images/admin/vehicle-${status.toLowerCase()}.png`, size: new window.google.maps.Size(29, 29), scaledSize: new window.google.maps.Size(29, 29), anchor: new window.google.maps.Point(14, 14) };
};

class VehicleFinder extends Component {
  constructor(props) {
    super(props);
    this.state = { searchActive: false, query: '', results: [], mapVehicles: [] };

    this.handleMapBoundsChange = throttle(500, this.handleMapBoundsChange.bind(this));
    this.loadMapForBounds = this.loadMapForBounds.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.dismissSearch = this.dismissSearch.bind(this);
    this.search = throttle(500, this.search);
    this.renderResult = this.renderResult.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicles | Flamingo Admin';
  }

  /* ------ Map ------ */

  handleMapBoundsChange(ne, sw, zoom) {
    return this.loadMapForBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng(), zoom, this.state.filters);
  }

  loadMapForBounds(neLatitude, neLongitude, swLatitude, swLongitude, zoom, filters) {
    const endpoint = `/vehicle/area?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}&zoom=${zoom}`;
    const currentBounds = { neLatitude, neLongitude, swLatitude, swLongitude, zoom };
    return api.get(endpoint)
      .then((res) => this.setState({ mapVehicles: res.data.data, currentBounds }))
      .catch(console.log);
  }

  /* ------ Search ------ */

  handleSearchFocus() {
    this.setState({ searchActive: true }, () => setTimeout(() => window.scrollTo(0, 0), 400));
  }

  handleSearchBlur() {
    this.setState({ searchActive: this.state.query.length > 0 });
  }

  handleSearchChange(query) {
    this.setState({ searching: true, searchActive: true, query }, () => {
      this.search(query);
    });
  }

  dismissSearch() {
    this.setState({ searchActive: false, query: '' });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ searching: false, results: [] });
      return;
    }
    api.post('/vehicle/search', { query, limit: 10 })
      .then((res) => this.setState({ results: res.data.data, searching: false }))
      .catch(this.handleApiError);
  }

  /* ------ Renders ------ */

  renderResult(vehicle, i) {
    return (
      <Link to={{ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } }} key={i}>
        <div className="fm-vehicles-result">
          <p className="fm-vehicles-result-registration">{ vehicle.registration }</p>
          <p className="fm-vehicles-result-qr">{ vehicle.qrCode }</p>
          <div className="fm-vehicles-result-right">
            <p className="fm-vehicles-result-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status }</p>
            <p className="fm-vehicles-result-imei">{ vehicle.iotCode }</p>
          </div>
        </div>
      </Link>
    );
  }

  renderOption(status, i) {
    const style = { color: '#222222', background: '#F8F7F9' };

    if (this.state.filters.statuses.includes(status)) {
      style.background = colors.status[status];
      style.color = '#FFF';
    }

    return (
      <span
        className="fm-vehicle-finder-filters-status"
        key={i}
        style={style}
        onClick={() => this.toggleStatus(status)}
      >
        { status }
      </span>
    );
  }

  render() {
    const { searchActive, searching, mapVehicles, results, query } = this.state;
    const sourceClass = 'fm-vehicle-finder-source fm-vehicle-finder-source-' + (searchActive ? 'search' : 'map');
    const searchClass = 'fm-vehicle-finder-search fm-vehicle-finder-search-' + (searchActive ? 'active' : 'inactive');

    return (
      <div className="fm-vehicle-finder">
        <div className={sourceClass}>
          <Map google={window.google} onBoundsChange={this.handleMapBoundsChange} isGlobal>
            { mapVehicles.map((vehicle, i) => <Marker key={i} position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={vehicle.registration} icon={vehicleIcon(vehicle.status, vehicle.batteryPercent)} onClick={() => history.push({ pathname: `/vehicle/${vehicle.id}`, state: { vehicle } })} />) }
          </Map>
        </div>
        <div className="fm-vehicle-finder-bar">
          <Input
            placeholder="Search"
            inputMode="numeric"
            value={this.state.query}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur} />
          {
            searchActive &&
            <div className="fm-vehicle-finder-bar-qr" onClick={this.dismissSearch}>
              <Icon icon="times" />
            </div>
          }
        </div>
        <LoadingLine hide={!searching} />
        <div className={searchClass}>
          { (!searching && results.length === 0 && query.length > 0) && <p className="fm-vehicle-finder-results-empty">No vehicles found</p> }
          { results.map(this.renderResult) }
        </div>
      </div>
    );
  }
}

export default VehicleFinder;
