import React from 'react';

class LoadingLine extends React.Component {
  render() {
    return (
      <div className="fm-loading-line">
        { !this.props.hide && <div className="fm-loading-line-inner"></div> }
      </div>
    );
  }
}

export default LoadingLine;
