import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import IncidentMap from './components/incident-map';
import IncidentEvents from './components/incident-events';
import IncidentView from './components/incident-view';

class Incident extends Component {
  render() {
    return (
      <Switch>
        <Route path="/incident/map" component={IncidentMap} />
        <Route path="/incident/:id(\d+)" component={IncidentView} />
        <Route path="/incident/:search?/:query?" component={IncidentEvents} />
      </Switch>
    );
  }
}

export default Incident;
