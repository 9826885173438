const status = {
  'INUSE': '#00A8FF',
  'AVAILABLE': '#4CD137',
  'UNAVAILABLE': '#718093',
  'RESERVED': '#FBC531',
  'CHARGING': '#FBC531',
  'MAINTENANCE': '#E84118',
  'TRANSIT': '#2F3640',
  'DEMO': '#A55EEA',
  'REPAIR': '#F0932B',
  'IOT': '#CCCCCC',
  'OFFLINE': '#CCCCCC',
};

const helmet = {
  'unlocked': '#3498db',
  'returned': '#2ecc71',
  'fined': '#e74c3c',
  'dismissed': '#34495e',
};

const ticket = {
  'resolved': '#6AB04C',
  'unresolved': '#EB4D4B',
  'open': '#F0932B',
  'unassigned': '#EB4D4B',
};

const voucher = {
  'CREDIT': '#A3CB38',
  'MINUTES': '#12CBC4',
  'UNLOCKS': '#D980FA',
};

const task = {
  'deploy': '#00a8ff',
  'pickup': '#EB3B5A',
  'batterySwap': '#4B7BEC',
  'rebalance': '#f39c12',
  'charge': '#f1c40f',
  'inspection': '#A55EEA',
};

const vehicleTicket = {
  'note': '#f0932b',
  'maintenance': '#eb4d4b',
  'missing': '#f1c40f',
  'accident': '#eb4d4b',
  'movement': '#f0932b',
  'theft': '#e056fd',
  'noParking': '#f1c40f',
};

const taskStatus = {
  'reserved': '#f1c40f',
  'available': '#20BF6B',
  'collected': '#f1c40f',
  'expired': '#999',
  'completed': '#000000',
  'cancelled': '#999999',
};

const inspection = {
  'PASS': '#6AB04C',
  'FAIL': '#EB4D4B',
};

const performanceActivity = {
  'available': status.AVAILABLE,
  'on_trip': status.INUSE,
  'removed': task.rebalance,
  'inspection': task.inspection,
  'batteryOpen': task.batterySwap,
};

const subscription = {
  'pending': '#7f8fa6',
  'active': '#4cd137',
  'scheduled': '#00a8ff',
  'finished': '#7f8fa6',
  'cancelled': '#fbc531',
};

const payment = {
  'pending': '#7f8fa6',
  'successful': '#4cd137',
  'failed': '#e84118',
};

const incident = {
  'nearMiss': '#f1c40f',
  'incident': '#eb4d4b',
  'theft': '#e056fd',
  'vandalism': '#f0932b',
};

const colors = { status, helmet, ticket, voucher, task, taskStatus, vehicleTicket, inspection, performanceActivity, subscription, payment, incident };

export default colors;
