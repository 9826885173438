import axios from 'axios';
import auth from 'helpers/auth';

const apiUrl = 'https://insights.api.rideflamingo.com';
// const apiUrl = 'http://localhost:3001';

const request = (method, endpoint, data = null, config = {}, needsAuthorization = true) => {
  if (needsAuthorization) {
    return auth.getIdToken().then((idToken) => {
      const initialConfig = { method, url: apiUrl + endpoint, headers: { Authorization: idToken }, data };
      return axios({ ...initialConfig, ...config });
    });
  }
  const initialConfig = { method: method, url: apiUrl + endpoint, data };
  return axios({ ...initialConfig, ...config });
};

const get = (endpoint, data = null, config = {}, needsAuthorization = true) => {
  return request('GET', endpoint, data, config, needsAuthorization);
};

const post = (endpoint, data = null, config = {}, needsAuthorization = true) => {
  return request('POST', endpoint, data, config, needsAuthorization);
};

const api = { get, post };

export default api;
