import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from 'helpers/history';

import Platform from 'components/platform';
import Login from 'components/login';
import Invitation from 'components/invitation';

class App extends Component {
  render() {
    return (
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <div className="fm-app">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/invitation/:token" component={Invitation} />
            <Route path="*" component={Platform} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
