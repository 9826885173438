import React from 'react';

class Marker extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.renderMarker();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map || this.props.position.lat !== prevProps.position.lat || this.props.position.lng !== prevProps.position.lng) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.renderMarker();
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  renderMarker() {
    const { map, google, position, title, icon, draggable, opacity, label } = this.props;
    const markerObj = { map, title, position, icon, draggable, opacity, label };
    this.marker = new google.maps.Marker(markerObj);
    this.marker.addListener('click', this.handleClick);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return null;
  }
}

export default Marker;
