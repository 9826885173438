import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Input from 'components/common/input';
import history from 'helpers/history';
import colors from 'helpers/colors';
import api from 'helpers/api';

const types = { incident: 'Incident', nearMiss: 'Near Miss' };
const severities = [{ value: 'minor', label: 'Minor and above' }, { value: 'moderate', label: 'Moderate and above' }, { value: 'significant', label: 'Significant and above' }, { value: 'major', label: 'Major and above' }, { value: 'severe', label: 'Severe' }];
const months = ['December', 'November', 'October', 'September', 'August', 'July', 'June', 'May', 'April', 'March', 'February', 'January'];
const years = [moment().format('YYYY'), moment().subtract(1, 'year').format('YYYY'), moment().subtract(2, 'year').format('YYYY'), moment().subtract(3, 'year').format('YYYY')];

class IncidentEvents extends Component {
  constructor(props) {
    super(props);
    const query = props.match.params.query ? decodeURIComponent(props.match.params.query) : '';
    this.state = {
      results: [],
      recent: [],
      query,
      loading: false,
      type: 'all',
      severity: 'all',
      month: 'all',
    };

    this.loadRecent = this.loadRecent.bind(this);
    this.handleError = this.handleError.bind(this);
    this.search = throttle(500, this.search);
    this.renderIncident = this.renderIncident.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSeverityChange = this.handleSeverityChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Incidents | Flamingo Admin';
    if (this.state.query.length > 0) {
      this.search(this.state.query);
    }
    this.loadRecent();
  }

  // Networking

  loadRecent() {
    const { type, severity, month } = this.state;
    let endpoint = '/incident/recent';
    const params = [];
    if (type !== 'all') {
      params.push(`type=${type}`);
    }
    if (month !== 'all') {
      params.push(`month=${moment(month, 'MMMM YYYY').format('YYYY-MM-DD')}`);
    }
    if (severity !== 'all') {
      params.push(`severity=${severity}`);
    }
    if (params.length > 0) {
      endpoint += `?${params.join('&')}`
    }
    api.get(endpoint)
      .then((res) => this.setState({ recent: res.data.data, loading: this.state.query.length > 0 }))
      .catch(this.handleError);
  }

  search(query) {
    if (query.length < 2) {
      history.replace(`/incident`);
      this.setState({ loading: false });
      return;
    }
    history.replace(`/incident/search/${encodeURIComponent(query)}`)
    api.post('/incident/search', { query })
      .then((res) => this.setState({ results: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Handlers

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  handleTypeChange(e) {
    this.setState({ type: e.target.value, loading: true }, this.loadRecent);
  }

  handleSeverityChange(e) {
    this.setState({ severity: e.target.value, loading: true }, this.loadRecent);
  }

  handleMonthChange(e) {
    this.setState({ month: e.target.value, loading: true }, this.loadRecent);
  }

  severityTypes() {
    alert('Minor\nInjuries or ailments not requiring medical treatment apart from minor first aid.\n\nModerate\nMinor injury or medical treatment case requiring A&E.\n\nSignificant\nSerious injury causing hospitalisation or multiple medical treatment cases.\n\nMajor\nLife threatening injury or multiple serious injuries causing hospitalisation.\n\nSevere\nDeath or multiple life threatening injuries.');
  }

  // Renders

  renderIncident(incident, i) {
    return (
      <Link to={{ pathname: `/incident/${incident.id}`, state: { incident } }} key={i}>
        <div className="fm-incident-events-result">
          <p className="fm-incident-events-result-title">{ types[incident.type] } - { moment(incident.startTime).format('dddd, Do MMM YYYY') }</p>
          <div className="fm-incident-events-result-bottom">
            <div className="fm-incident-events-result-dot" style={{ backgroundColor: colors.incident[incident.type] }}></div>
            { incident.severity && <p className="fm-incident-events-result-item">{ incident.severity } &#xb7; </p> }
            { incident.name && <p className="fm-incident-events-result-item">{ incident.location }</p> }
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, query, results, recent, error, type, severity, month } = this.state;

    const mapAction = { to: '/incident/map', icon: 'map' };
    const helpAction = { onClick: this.severityTypes, icon: 'info-circle' };

    return (
      <div className="fm-incident-events">
        <NavigationBar title="Incidents" loading={loading} rightActions={[mapAction, helpAction]} />
        <div className="fm-incident-events-content">
          <div className="fm-incident-events-filter">
            <div className="fm-incident-events-filter-group">
              <select value={type} onChange={this.handleTypeChange} className="fm-input fm-input-select">
                <option value="all">All Types</option>
                { Object.keys(types).map((type, i) => <option key={i} value={type}>{types[type]}</option>) }
              </select>
              <select value={severity} onChange={this.handleSeverityChange} className="fm-input fm-input-select">
                <option value="all">All Severities</option>
                { severities.map((severity, i) => <option key={i} value={severity.value}>{severity.label}</option>) }
              </select>
              <select value={month} onChange={this.handleMonthChange} className="fm-input fm-input-select">
                <option value="all">All Months</option>
                { years.map((year, i) => months.map((month, ii) => <option key={`${month} ${year}`} value={`${month} ${year}`}>{`${month} ${year}`}</option>)) }
              </select>
            </div>
          </div>
          <div className="fm-incident-events-search-bar">
            <Input placeholder="Search for an incident" value={query} autoFocus={true} onChange={this.handleSearchChange} />
          </div>
          <div className="fm-incident-events-results">
            {
              query.length > 0 ? (
                <>
                  { results.map(this.renderIncident) }
                  { (!loading && results.length === 0 && query.length > 0) && <p className="fm-incident-events-results-empty">No incidents found</p> }
                </>
              ) : (
                <>
                  { recent.map(this.renderIncident) }
                  { (!loading && recent.length === 0) && <p className="fm-incident-events-results-empty">No incidents found. Try adjusting your search.</p> }
                </>
              )
            }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentEvents;
