import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';

class AnalyticsOperations extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM-DD')
    this.state = { date: today, today, period: 'day', dataPeriod: 'day', data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.renderBreakdown = this.renderBreakdown.bind(this);
  }

  componentDidMount() {
    document.title = 'Analytics | Flamingo Insights Platform';
    this.loadData();
  }

  // Networking

  loadData() {
    this.setState({ loading: true });
    const { date, period } = this.state;
    const dataPeriod = period;
    return api.get(`/analytics/overview?date=${date}&period=${period}`)
      .then((res) => this.setState({ data: res.data.data, dataPeriod, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  // Handlers

  handleDateChange(e) {
    this.setState({ date: e.target.value });
  }

  handlePeriodChange(e) {
    this.setState({ period: e.target.value });
  }

  // Renders

  renderBreakdown(period, data) {
    const keys = period === 'week' ? Object.keys(data).map((date) => moment(date).format('D/M')) : ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const values = Object.keys(data).reduce((a, date) => { a[moment(date).format(period === 'week' ? 'D/M' : 'HH')] = data[date]; return a; }, {});
    const maxTrips = Math.max.apply(Math, Object.values(values));

    return (
      <>
        <p className="fm-analytics-title">{ period === 'day' ? 'Trips per Hour' : 'Trips per Day' }</p>
        <div className="fm-analytics-trips">
          {
            keys.map((key, i) => {
              return (
                <div className="fm-analytics-trips-item" key={i}>
                  <div className="fm-analytics-trips-bar-holder">
                    <div className="fm-analytics-trips-bar" title={values[key] || 0} style={{ height: ((((values[key] || 0) / maxTrips) * 100) + '%') }}></div>
                  </div>
                  <p className="fm-analytics-trips-value">{ values[key] || 0 }</p>
                  <p className="fm-analytics-trips-date">{ key }</p>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }

  render() {
    const { loading, data, date, today, period, dataPeriod, error } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Analytics" loading={loading} />
        <div className="fm-analytics">
          <div className="fm-analytics-form">
            <select className="fm-input fm-input-select" value={period} onChange={this.handlePeriodChange}>
              <option value="day">For day</option>
              <option value="week">Week ending</option>
            </select>
            <input value={date} className="fm-input" type="date" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-button" onClick={this.loadData}>{ loading ? '...' : 'Go' }</button>
          </div>
          <div className="fm-analytics-bar">
            <div className="fm-analytics-bar-item">
              <Icon className="fm-analytics-bar-icon" icon="route" />
              <div className="fm-analytics-bar-text">
                <p className="fm-analytics-bar-value">{ data.tripCount || 0 }</p>
                <p className="fm-analytics-bar-label">Total Trips</p>
              </div>
            </div>
            <div className="fm-analytics-bar-item">
              <Icon className="fm-analytics-bar-icon" icon="stopwatch" />
              <div className="fm-analytics-bar-text">
                <p className="fm-analytics-bar-value">{ data.averageDuration || 0 } m</p>
                <p className="fm-analytics-bar-label">Average Duration</p>
              </div>
            </div>
            <div className="fm-analytics-bar-item">
              <Icon className="fm-analytics-bar-icon" icon="ruler-horizontal" />
              <div className="fm-analytics-bar-text">
                <p className="fm-analytics-bar-value">{ data.averageDistance || 0 } km</p>
                <p className="fm-analytics-bar-label">Average Distance</p>
              </div>
            </div>
            <div className="fm-analytics-bar-item">
              <Icon className="fm-analytics-bar-icon" icon="map-marked-alt" />
              <div className="fm-analytics-bar-text">
                <p className="fm-analytics-bar-value">{ data.deployedVehicles || 0 }</p>
                <p className="fm-analytics-bar-label">Available Vehicles</p>
              </div>
            </div>
          </div>
          {
            (data.tripCounts && data.tripCounts.length) > 1 &&
            <>
              <p className="fm-analytics-title">Trips by Area</p>
              <div className="fm-analytics-areas">
                {
                  data.tripCounts.map((item, i) => {
                    return (
                      <div className="fm-analytics-area">
                        <p className="fm-analytics-area-value">{ item.total }</p>
                        <p className="fm-analytics-area-label">{ item.label }</p>
                      </div>
                    );
                  })
                }
              </div>
            </>
          }
          { data.tripBreakdown && this.renderBreakdown(dataPeriod, data.tripBreakdown) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsOperations;
