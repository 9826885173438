import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Contacts extends Component {
  constructor() {
    super();
    this.state = { contacts: [], loading: true, error: false };

    this.loadContacts = this.loadContacts.bind(this);
    this.renderContact = this.renderContact.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Contacts | Flamingo Insights Platform';
    this.loadContacts();
  }

  loadContacts() {
    this.setState({ loading: true });
    return api.get('/contacts')
      .then((res) => this.setState({ contacts: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  renderContact(contact, i) {
    return (
      <tr key={i}>
        <td className="fm-contacts-td">{ contact.firstName } { contact.lastName }</td>
        <td className="fm-contacts-td-light"><a className="fm-contacts-link" href={`tel:${contact.phone}`}>{ contact.phone }</a></td>
        <td className="fm-contacts-td-light"><a className="fm-contacts-link" href={`mailto:${contact.email}`}>{ contact.email }</a></td>
      </tr>
    );
  }

  render() {
    const { loading, contacts, error } = this.state;

    return (
      <div className="fm-contacts">
        <NavigationBar title="Contacts" loading={loading} />
        <table className="fm-contacts-table">
          <tbody>
            { contacts.map(this.renderContact) }
          </tbody>
        </table>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Contacts;
