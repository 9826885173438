import React from 'react';
import Loading from '../loading';

class Button extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <button className="fm-button">
          <Loading />
        </button>
      );
    }
    return <button className="fm-button" id={this.props.id} onClick={() => this.props.onClick ? this.props.onClick() : {}}>{ this.props.children }</button>;
  }
}

export default Button;
