import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import api from 'helpers/api';

const types = { incident: 'Incident', nearMiss: 'Near Miss', vandalism: 'Vandalism', theft: 'Theft' };
const activities = { opened: { icon: 'star', color: '#eb3b5a' }, closed: { icon: 'check-circle', color: '#20bf6b' }, insights: { icon: 'chart-bar', color: '#4B7BEC' }, updated: { icon: 'caret-square-up', color: '#f0932b' }, note: { icon: 'sticky-note', color: '#f1c40f' }, image: { icon: 'image', color: '#f1c40f' }, inspection: { icon: 'tools', color: '#A55EEA' } };
const severities = { minor: `Injuries or ailments not requiring medical treatment apart from minor first aid.`, moderate: `Minor injury or medical treatment case requiring A&E.`, significant: `Serious injury causing hospitalisation or multiple medical treatment cases.`, major: `Life threatening injury or multiple serious injuries causing hospitalisation.`, severe: `Death or multiple life threatening injuries.` };


class IncidentView extends Component {
  constructor() {
    super();
    this.state = { incident: {}, activityFileUrl: false, activityContent: '', activityInsights: false, loading: true, error: false };

    this.loadIncident = this.loadIncident.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderActivity = this.renderActivity.bind(this);
  }

  componentDidMount() {
    document.title = 'Incident Details | Flamingo Insights';
    this.loadIncident();
  }

  // Networking

  loadIncident() {
    this.setState({ error: false, loading: true });
    return api.get('/incident/' + this.props.match.params.id)
      .then((res) => this.setState({ incident: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Renders

  renderActivity(item, i) {
    const icon = activities[item.subtype];
    return (
      <div className="fm-incident-view-activity-item" key={i}>
        <div className="fm-incident-view-activity-item-icon" style={{ color: icon.color }}>
          <Icon icon={icon.icon} />
        </div>
        <div className="fm-incident-view-activity-item-details">
          { item.type === 'text' && <p className="fm-incident-view-activity-item-text">{ item.content }</p> }
          { item.type === 'event' && <p className="fm-incident-view-activity-item-event">{ item.content }</p> }
          { item.type === 'image' && <img className="fm-incident-view-activity-item-image" alt="Feed" src={ item.content } /> }
          <p className="fm-incident-view-activity-item-bottom">{ moment(item.createdAt).format('h:mma D/MM/YY') }</p>
        </div>
      </div>
    );
  }

  render() {
    const { loading, incident, error } = this.state;

    let title = "Incident Details";
    if (incident.type && incident.startTime) {
      title = `${types[incident.type] } - ${moment(incident.startTime).format('dddd, Do MMM YYYY')}`;
    }

    return (
      <div className="fm-incident-view">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-incident-view-content">
          <div className="fm-incident-view-details">
            {
              (incident.startTime && incident.endTime) &&
              <div className="fm-incident-view-duration">
                <div className="fm-incident-view-duration-inner">
                  <div className="fm-incident-view-duration-line"></div>
                  <p className="fm-incident-view-duration-time">{ moment(incident.startTime).format('h:mma D/MM/YY') }</p>
                  <p className="fm-incident-view-duration-value">{ moment(incident.endTime).diff(incident.startTime, 'minutes') } mins</p>
                  <p className="fm-incident-view-duration-time">{ moment(incident.endTime).format('h:mma D/MM/YY') }</p>
                </div>
              </div>
            }
          </div>
          {
            incident.location &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Location</p>
              <p className="fm-incident-view-outcome">{ incident.location }</p>
            </div>
          }
          {
            incident.weather &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Weather</p>
              <p className="fm-incident-view-outcome">{ incident.weather }</p>
            </div>
          }
          {
            incident.severity &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">Severity</p>
              <p className="fm-incident-view-outcome"><span style={{ textTransform: 'capitalize' }}>{ incident.severity }</span> - { severities[incident.severity] }</p>
            </div>
          }
          {
            incident.outcome &&
            <div className="fm-incident-view-cause">
              <p className="fm-incident-view-subtitle">{ ['incident','nearMiss'].includes(incident.type) ? 'Root Cause' : 'Outcome' }</p>
              <p className="fm-incident-view-outcome">{ incident.status === 'closed' ? incident.outcome : 'Incident still in review.' }</p>
            </div>
          }
          <div className="fm-incident-view-activity">
            <p className="fm-incident-view-subtitle">Activity</p>
            <div className="fm-incident-view-activity-feed">
              <div className="fm-incident-view-activity-feed-line"></div>
              { (incident.activity || []).map(this.renderActivity) }
            </div>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentView;
