import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../common/icon';
import user from '../../helpers/user';
import auth from '../../helpers/auth';

class More extends React.Component {
  constructor() {
    super();
    this.state = { user: { admin: {} } };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    user.getCurrentUser().then((user) => this.setState({ user }));
  }

  handleLogout() {
    auth.logout();
    user.clearUser();
  }

  render() {
    const links = [
      { icon: 'tachometer-alt', label: 'Dashboard', path: '/', mobile: true },
      { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
      { icon: 'chart-area', label: 'Analytics', path: '/analytics', mobile: true },
      { icon: 'users', label: 'Demographics', path: '/demographics', mobile: true },
      { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident', mobile: false, regionId: 15 },
      { icon: 'project-diagram', label: 'Maps', path: '/maps', mobile: false },
      { icon: 'database', label: 'Data Sharing', path: '/data-sharing', mobile: false },
      { icon: 'envelope', label: 'Contact', path: '/contact', mobile: false },
    ];

    const user = this.state.user;

    // Render
    return (
      <div className="fm-more">
        <div className="fm-more-profile">
          <div className="fm-more-profile-avatar"></div>
          <div className="fm-more-profile-details">
            <p className="fm-more-profile-name">{ user.firstName } { user.lastName }</p>
            <p className="fm-more-profile-region">{ (user.insights && user.insights.region) ? user.insights.region.name : 'Flamingo' }</p>
          </div>
        </div>
        <div className="fm-more-navigation">
          { links.filter((link) => !link.regionId || (user.insights && user.insights.region && user.insights.region.id === link.regionId)).map((link, i) => <Link key={i} className="fm-more-link" to={link.path} onClick={this.handleClick}><Icon icon={link.icon} />{link.label}</Link>) }
        </div>
        <div className="fm-more-extra">
          <Link className="fm-more-link" to="/login" onClick={this.handleLogout}><Icon icon="sign-out-alt" />Logout</Link>
        </div>
      </div>
    );
  }
}

export default More;
