import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import history from 'helpers/history';
import auth from 'helpers/auth';

import TabBar from 'components/common/tab-bar';
import Loading from 'components/common/loading';

import Dashboard from 'components/dashboard';
import Vehicles from 'components/vehicles';
import Vehicle from 'components/vehicle';
import Contact from 'components/contact';
import Analytics from 'components/analytics';
import Demographics from 'components/demographics';
import Incident from 'components/incident';
import Maps from 'components/maps';
import DataSharing from 'components/data-sharing';
import More from 'components/more';

class Platform extends Component {
  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    auth.init((authed) => authed ? this.setState({ loading: false }) : history.push('/login'));
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="fm-platform">
        <div className="fm-platform-content">
          <Switch>
            <Route path="/vehicle/:vehicleId" component={Vehicle} />
            <Route path="/vehicles" component={Vehicles} />
            <Route path="/contact" component={Contact} />
            <Route path="/analytics" component={Analytics} />
            <Route path="/maps" component={Maps} />
            <Route path="/demographics" component={Demographics} />
            <Route path="/incident" component={Incident} />
            <Route path="/data-sharing" component={DataSharing} />
            <Route path="/more" component={More} />
            <Route path="/" component={Dashboard} />
          </Switch>
        </div>
        <TabBar path={this.props.location.pathname} />
      </div>
    );
  }
}

export default Platform;
