import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';
import user from 'helpers/user';
import history from 'helpers/history';

const types = { incident: 'Incident', nearMiss: 'Near Miss' };
const severities = [{ value: 'minor', label: 'Minor and above' }, { value: 'moderate', label: 'Moderate and above' }, { value: 'significant', label: 'Significant and above' }, { value: 'major', label: 'Major and above' }, { value: 'severe', label: 'Severe' }];
const months = ['December', 'November', 'October', 'September', 'August', 'July', 'June', 'May', 'April', 'March', 'February', 'January'];
const years = [moment().format('YYYY'), moment().subtract(1, 'year').format('YYYY'), moment().subtract(2, 'year').format('YYYY'), moment().subtract(3, 'year').format('YYYY')];

class IncidentMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incidents: [],
      region: false,
      loading: false,
      error: false,
      type: 'all',
      severity: 'all',
      month: 'all',
    };

    this.loadLocation = this.loadLocation.bind(this);
    this.loadIncidents = this.loadIncidents.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleSeverityChange = this.handleSeverityChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Incident Map | Flamingo Insights';
    this.loadIncidents();
    this.loadLocation();
  }

  // Networking

  loadLocation() {
    user.getCurrentUser().then((user) => this.setState({ region: user.insights.region, loading: false }));
  }

  // Networking

  loadIncidents() {
    const { type, severity, month } = this.state;
    let endpoint = '/incident/area';
    const params = [];
    if (type !== 'all') {
      params.push(`type=${type}`);
    }
    if (month !== 'all') {
      params.push(`month=${moment(month, 'MMMM YYYY').format('YYYY-MM-DD')}`);
    }
    if (severity !== 'all') {
      params.push(`severity=${severity}`);
    }
    if (params.length > 0) {
      endpoint += `?${params.join('&')}`
    }
    this.setState({ error: false, loading: true });
    api.get(endpoint)
      .then((res) => this.setState({ incidents: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    console.log(e);
    this.setState({ error, loading: false });
  }

  // Handlers

  handleTypeChange(e) {
    this.setState({ type: e.target.value });
  }

  handleSeverityChange(e) {
    this.setState({ severity: e.target.value });
  }

  handleMonthChange(e) {
    this.setState({ month: e.target.value });
  }

  // Renders

  render() {
    const { loading, incidents, region, type, severity, month, error } = this.state;

    return (
      <div className="fm-incident-map">
        <NavigationBar title="Incident Map" loading={loading} showBack={true} />
        {
          region &&
          <Map options={{ center: { lat: region.centreLatitude, lng: region.centreLongitude }, zoom: 14 }} google={window.google} isGlobal hideLocation>
            { incidents.map((incident, i) => <Marker key={i} position={{ lat: incident.latitude, lng: incident.longitude }} title={`${incident.id}`} onClick={(e) => history.push({ pathname: `/incident/${incident.id}`, state: { incident } })} />)}
          </Map>
        }
        <div className="fm-maps-options">
          <select value={type} onChange={this.handleTypeChange} className="fm-input fm-input-select">
            <option value="all">All Types</option>
            { Object.keys(types).map((type, i) => <option key={i} value={type}>{types[type]}</option>) }
          </select>
          <select value={severity} onChange={this.handleSeverityChange} className="fm-input fm-input-select">
            <option value="all">All Severities</option>
            { severities.map((severity, i) => <option key={i} value={severity.value}>{severity.label}</option>) }
          </select>
          <select value={month} onChange={this.handleMonthChange} className="fm-input fm-input-select">
            <option value="all">All Months</option>
            { years.map((year, i) => months.map((month, ii) => <option key={`${month} ${year}`} value={`${month} ${year}`}>{`${month} ${year}`}</option>)) }
          </select>
          <button className="fm-maps-options-button" disabled={loading} onClick={this.loadIncidents}>{ loading ? '...' : 'Go' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default IncidentMap;
