import React from 'react';
import moment from 'moment';

class Input extends React.Component {
  constructor() {
    super();
    this.input = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    if (this.props.onChange) {
      if (this.props.type === 'date') {
        return this.props.onChange(moment(e.target.value).toDate());
      }
      if (this.props.type === 'checkbox') {
        return this.props.onChange(e.target.checked);
      }
      if (this.props.type === 'file') {
        return this.props.onChange(e.target.files[0]);
      }
      return this.props.onChange(e.target.value);
    }
  }

  handleFocus() {
    if (this.props.onFocus) {
      return this.props.onFocus();
    }
  }

  handleBlur() {
    if (this.props.onBlur) {
      return this.props.onBlur();
    }
  }

  render() {
    if (this.props.type === 'select') {
      return (
        <div className={'fm-input-container'}>
          { this.props.label && <label className={'fm-input-label'}>{this.props.label}</label> }
          <select
            disabled={this.props.disabled}
            className="fm-input fm-input-select"
            value={this.props.value || ''}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
          >
            { (!this.props.value && this.props.defaultOption) && <option value="" disabled hidden>{ this.props.defaultOption }</option> }
            { (this.props.options || []).map((opt, i) => <option key={i} value={ opt.value || opt }>{ opt.label || opt }</option>) }
          </select>
        </div>
      );
    }

    if (this.props.type === 'checkbox') {
      return (
        <div className={'fm-input-container'}>
          { this.props.label && <label className={'fm-input-label'}>{this.props.label}</label> }
          <label className={'fm-input-checkbox-container'}>
            <input ref={this.input} className={'fm-input fm-input-checkbox'} disabled={this.props.disabled || false} type={'checkbox'} checked={this.props.checked || false} onChange={this.handleChange} />
            <span className="fm-input-checkbox-checkmark"></span>
          </label>
        </div>
      );
    }

    if (this.props.type === 'textarea') {
      return (
        <div className={'fm-input-container'}>
          { this.props.label && <label className={'fm-input-label'}>{this.props.label}</label> }
          <textarea
            className={'fm-input fm-input-textarea'}
            placeholder={this.props.placeholder}
            rows={5}
            autoFocus={this.props.autoFocus || false}
            disabled={this.props.disabled || false}
            value={this.props.value || ''}
            onChange={this.handleChange}>
          </textarea>
        </div>
      );
    }

    const value = this.props.type === 'date' ? moment(this.props.value).format('YYYY-MM-DD') : this.props.value;
    return (
      <div className={'fm-input-container'}>
        { this.props.label && <label className={'fm-input-label'}>{this.props.label}</label> }
        <input ref={this.input} accept={this.props.accept} inputMode={ this.props.inputMode || 'text' } autoComplete="new-password" autoFocus={this.props.autoFocus || false} className={'fm-input'} placeholder={this.props.placeholder} disabled={this.props.disabled || false} type={this.props.type || 'text'} value={value || ''} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} />
      </div>
    );
  }
}

export default Input;
