import React from 'react';

class Icon extends React.Component {
  render() {
    const style = {};
    if (this.props.color) {
      style.color = this.props.color;
    }
    if (this.props.size) {
      style.fontSize = this.props.size + 'px';
    }
    const className = (this.props.regular ? 'far' : 'fa') + ' fa-' + (this.props.icon || 'question-circle') + (this.props.className ? ' ' + this.props.className : '');
    return <i className={className} style={style}></i>;
  }
}

export default Icon;
