import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import user from 'helpers/user';
import auth from 'helpers/auth';

class TabBar extends React.Component {
  constructor() {
    super();
    this.state = { user: { admin: {} } };
    this.handleLogout = this.handleLogout.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }

  componentDidMount() {
    this.loadUser(false);
  }

  loadUser(forceRefresh) {
    user.getCurrentUser(forceRefresh).then((user) => this.setState({ user }));
  }

  handleLogout() {
    auth.logout();
    user.clearUser();
  }

  render() {
    const { user } = this.state;
    const links = [
      { icon: 'tachometer-alt', label: 'Dashboard', path: '/', mobile: true },
      { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
      { icon: 'chart-area', label: 'Analytics', path: '/analytics', mobile: true },
      { icon: 'users', label: 'Demographics', path: '/demographics', mobile: true },
      { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident', mobile: false, regionId: 15 },
      { icon: 'project-diagram', label: 'Maps', path: '/maps', mobile: false },
      { icon: 'database', label: 'Data Sharing', path: '/data-sharing', mobile: false },
      { icon: 'envelope', label: 'Contact', path: '/contact', mobile: false },
    ];

    return (
      <div className="fm-tab-bar">
        <div className="fm-tab-bar-header">
          <Link className="fm-tab-bar-logo" to="/"></Link>
        </div>
        <div className="fm-tab-bar-items">
          { links.filter((link) => !link.regionId || (user.insights && user.insights.region && user.insights.region.id === link.regionId)).map((link, i) => <Link key={i} className={`fm-tab-bar-item${link.mobile ? '' : ' fm-tab-bar-item-desktop'}`} to={link.path}><Icon icon={link.icon} />{link.label}</Link>) }
          <Link className="fm-tab-bar-item fm-tab-bar-item-mobile" to="/more"><Icon icon="bars" />More</Link>
          <Link className="fm-tab-bar-item fm-tab-bar-item-desktop" to="/login" onClick={this.handleLogout}><Icon icon="sign-out-alt" />Logout</Link>
        </div>
        <div className="fm-tab-bar-profile">
          <div className="fm-tab-bar-profile-avatar"></div>
          <div className="fm-tab-bar-profile-details">
            <p className="fm-tab-bar-profile-name">{ user.firstName } { user.lastName }</p>
            <p className="fm-tab-bar-profile-region">{ (user.insights && user.insights.region) ? user.insights.region.name : 'Flamingo' }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TabBar;
