import React from 'react';

class HeatMap extends React.Component {
  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map)) {
      if (this.layer) {
        this.layer.setMap(null);
      }
      this.renderLayer();
    }
  }

  componentWillUnmount() {
    if (this.layer) {
      this.layer.setMap(null);
    }
  }

  renderLayer() {
    const { map, google, points } = this.props;
    const data = points.map((point) => new google.maps.LatLng(point[0], point[1]));
    const layerObj = { map, data };
    this.layer = new google.maps.visualization.HeatmapLayer(layerObj);
  }

  render() {
    return null;
  }
}

export default HeatMap;
