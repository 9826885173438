import React from 'react';

class Polylines extends React.Component {

  componentDidMount() {
    this.renderPolylines();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map) || (this.props.encodedPaths.length !== prevProps.encodedPaths.length)) {
      if (this.polylines) {
        this.polylines.map((polyline) => polyline.setMap(null));
      }
      this.renderPolylines();
    }
  }

  componentWillUnmount() {
    if (this.polylines) {
      this.polylines.map((polyline) => polyline.setMap(null));
    }
  }

  renderPolylines() {
    const { map, google, encodedPaths, strokeColor, strokeWeight, strokeOpacity } = this.props;
    const paths = encodedPaths.map((encodedPath) => google.maps.geometry.encoding.decodePath(encodedPath));
    this.polylines = paths.map((path) => new google.maps.Polyline({ map, path, strokeColor, strokeWeight, strokeOpacity }));
  }

  render() {
    return null;
  }
}

export default Polylines;
