import React, { Component } from 'react';
import moment from 'moment';
import Loading from 'components/common/loading';
import Toast from 'components/common/toast';
import Map, { Marker } from 'components/common/map';
import api from 'helpers/api';

const statusColors = {
  'RESERVED': '#4834D4',
  'AVAILABLE': '#6AB04C',
  'UNAVAILABLE': '#535C68',
  'REMOVED': '#F9CA24',
  'INUSE': '#4834D4',
};

const reasonMessages = {
  'SERVICE_START': 'This vehicle was deployed.',
  'USER_DROP_OFF': 'A user ended their ride on this vehicle.',
  'REBALANCE_DROP_OFF': 'This vehicle was dropped off after rebalancing.',
  'MAINTENANCE_DROP_OFF': 'This vehicle was dropped off after maintenance.',
  'USER_PICK_UP': 'A user began their ride on this vehicle.',
  'MAINTENANCE': 'This vehicle was disabled due to maintenance.',
  'LOW_BATTERY': 'This vehicle was disabled due to low battery.',
  'SERVICE_END': 'This vehicle was picked up by our team.',
  'REBALANCE_PICK_UP': 'This vehicle was picked up for rebalancing.',
  'MAINTENANCE_PICK_UP': 'This vehicle was picked up for maintenance.',
};

class Vehicle extends Component {
  constructor() {
    super();
    this.state = { vehicle: {}, changes: [], loading: true };
    this.loadVehicle = this.loadVehicle.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderStatusChange = this.renderStatusChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Details | Flamingo Insights Platform';
    this.loadVehicle();
  }

  loadVehicle() {
    return api.get('/vehicle/' + this.props.match.params.vehicleId)
      .then((res) => this.setState({ vehicle: res.data.data }))
      .then(() => api.get('/vehicle/' + this.props.match.params.vehicleId + '/status-changes'))
      .then((res) => this.setState({ changes: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, updating: false, isRefreshing: false });
  }

  renderStatusChange(change, i) {
    return (
      <div className="fm-vehicle-change" key={i}>
        <p className="fm-vehicle-change-type" style={{ backgroundColor: statusColors[change.eventType] }}>{ change.eventType }</p>
        <p className="fm-vehicle-change-reason">{ reasonMessages[change.eventTypeReason] }</p>
        <p className="fm-vehicle-change-time">{ moment(change.eventTime).format('h:mma D/M/YY') }</p>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const { vehicle } = this.state;
    const mapOptions = { center: { lat: vehicle.latitude, lng: vehicle.longitude }, zoom: 14 };
    return (
      <div className="fm-page fm-vehicle">
        <p className="fm-page-title">Vehicle Details</p>
        <div className="fm-vehicle-details">
          <div className="fm-vehicle-details-top">
            <img className="fm-vehicle-details-top-preview" alt="Segway" src="https://storage.googleapis.com/flamingo-static/images/admin/scooter.png" />
            <div className="fm-vehicle-details-top-middle">
              <p className="fm-vehicle-details-registration">{ vehicle.registration }</p>
            </div>
            <div className="fm-vehicle-details-top-right">
              <p className="fm-vehicle-details-status" style={{ backgroundColor: statusColors[vehicle.status] }}>{ vehicle.status }</p>
              <p className="fm-vehicle-details-updated">{ moment(vehicle.updatedAt).fromNow() }</p>
            </div>
          </div>
          <div className="fm-vehicle-details-map">
            <Map options={mapOptions} google={window.google}>
              <Marker position={{ lat: vehicle.latitude, lng: vehicle.longitude }} title={vehicle.registration} />
            </Map>
          </div>
          <div className="fm-vehicle-changes">
            <p className="fm-vehicle-changes-title">RECENT STATUS CHANGES</p>
            { this.state.changes.map(this.renderStatusChange) }
          </div>
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default Vehicle;
