import React from 'react';
import Loading from '../loading';
import styles from './flamingo-google-maps-style.json'

class Map extends React.Component {
  constructor() {
    super();
    this.mapContainer = React.createRef();
    this.loadMaps = this.loadMaps.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.locationSuccess = this.locationSuccess.bind(this);
    this.state = { loading: false, map: null };
  }

  componentDidMount() {
    this.loadMaps();
    this.loadLocation();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map || this.props.options) {
      if (this.props.options.center.lat !== prevProps.options.center.lat || this.props.options.center.lng !== prevProps.options.center.lng) {
        if (this.map) {
          const center = new window.google.maps.LatLng(this.props.options.center.lat, this.props.options.center.lng);
          this.map.panTo(center);
        }
      }
    }
  }

  componentWillUnmount() {
    window.google.maps.event.clearListeners(this.map, 'bounds_changed');
    this.map = null;
    navigator.geolocation.clearWatch(this.location);
  }

  loadMaps() {
    const options = { center: { lat: -41.288170, lng: 174.778952 }, zoom: 16, ...this.props.options, styles: styles };
    const ls = localStorage.getItem('mapLocation');
    if (this.props.isGlobal && ls) {
      const storage = JSON.parse(ls);
      options.center = storage.center;
      options.zoom = storage.zoom;
    } else if (!this.props.options) {
      options.center = { lat: -41.293024, lng: 174.777284 };
      options.zoom = 10;
    }
    const map = new window.google.maps.Map(this.mapContainer.current, options);
    map.addListener('idle', this.handleDragEnd);
    this.map = map;
    this.setState({ loading: false });
  }

  loadLocation() {
    const options = { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 };
    this.location = navigator.geolocation.watchPosition(this.locationSuccess, this.locationError, options);
  }

  locationSuccess(pos) {
    if (!this.props.hideLocation) {
      const position = { lat: pos.coords.latitude, lng: pos.coords.longitude };
      if (this.locationMarker === undefined) {
        const icon = { url: 'https://storage.googleapis.com/flamingo-static/images/admin/location.png', size: new window.google.maps.Size(25, 25), scaledSize: new window.google.maps.Size(25, 25) };
        const markerObj = { map: this.map, position, icon };
        this.locationMarker = new window.google.maps.Marker(markerObj);
      } else {
        this.locationMarker.setPosition(position);
      }
    }
  }

  locationError(err) {
    console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  handleDragEnd(passedMap = false) {
    const bounds = this.map.getBounds();
    const center = this.map.getCenter();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const zoom = this.map.getZoom();
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange(ne, sw, zoom);
    }
    if (this.props.isGlobal) {
      window.localStorage.setItem('mapLocation', JSON.stringify({ center, zoom }));
    }
  }

  renderChildren() {
    const { children } = this.props;
    if (!children) return;

    return React.Children.map(children, c => {
      if (!c) return null;
      return React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
      });
    })
  }

  render() {
    return (
      <div className="fm-map" ref={this.mapContainer}>
        { this.state.loading && <Loading /> }
        { this.renderChildren() }
      </div>
    );
  }
}

export default Map;
