import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class DataSharing extends Component {
  constructor() {
    super();
    this.state = { gbfs: false, mdsCredential: false, loading: true, error: false };

    this.loadGBFS = this.loadGBFS.bind(this);
    this.loadMDSCredential = this.loadMDSCredential.bind(this);
    this.generateMDSCredential = this.generateMDSCredential.bind(this);
    this.handleMDSError = this.handleMDSError.bind(this);
    this.renderMDS = this.renderMDS.bind(this);
    this.renderGBFS = this.renderGBFS.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Data Sharing | Flamingo Insights Platform';
    this.loadGBFS();
  }

  // Networking

  loadGBFS() {
    this.setState({ loading: true });
    return api.get('/data-sharing/gbfs')
      .then((res) => this.setState({ gbfs: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadMDSCredential() {
    this.setState({ loading: true });
    return api.get('/data-sharing/mds/credential')
      .then((res) => this.setState({ mdsCredential: res.data.data, loading: false }))
      .catch(this.handleMDSError);
  }

  generateMDSCredential(preConfirmed) {
    if (preConfirmed === true || window.confirm('Are you sure you want to regenerate your secret? Your existing secret will be voided.')) {
      this.setState({ loading: true });
      return api.post('/data-sharing/mds/credential')
        .then((res) => this.setState({ mdsCredential: res.data.data, loading: false }))
        .catch(this.handleError);
    }
  }

  handleMDSError(error) {
    if (window.access(() => error.response.data.error)) {
      if (error.response.data.error === 'credential_not_found') {
        return this.generateMDSCredential(true);
      }
    }
    return this.handleError(error);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Renders

  renderMDS(mdsCredential, loading) {
    return (
      <div className="fm-data-section">
        <p className="fm-data-section-title">Mobility Data Specification</p>
        <p className="fm-data-section-text">The Mobility Data Specification (MDS), a project of the <a target="_blank" rel="noopener noreferrer" className="fm-data-section-link" href="http://www.openmobilityfoundation.org/">Open Mobility Foundation</a> (OMF), is a set of Application Programming Interfaces (APIs) focused on shared mobility services such as dockless scooters, bicycles, mopeds, and carshare, and inspired by projects like GTFS and GBFS. MDS is a digital tool that helps cities better manage transportation in the public right of way, standardizing communication and data-sharing between cities and private mobility providers, allowing cities to share and validate policy digitally, and enabling vehicle management and better outcomes for residents.</p>
        <p className="fm-data-section-text">For more information on this specification, visit the <a href="https://github.com/openmobilityfoundation/mobility-data-specification" target="_blank" rel="noopener noreferrer" className="fm-data-section-link">openmobilityfoundation/mobility-data-specification</a> repository.</p>
        <div className="fm-data-items">
          <p className="fm-data-item-line">
            <span className="fm-data-item-line-label">Version:</span>
            <span className="fm-data-item-line-value">1.1.0</span>
          </p>
          <p className="fm-data-item-line">
            <span className="fm-data-item-line-label">Client ID:</span>
            {
              mdsCredential ? (
                <span className="fm-data-item-line-value"> { mdsCredential.clientId }</span>
              ) : (
                <button className="fm-data-item-line-button" disabled={loading} onClick={this.loadMDSCredential}>Click to view</button>
              )
            }
          </p>
          {
            mdsCredential && (
              <p className="fm-data-item-line">
                <span className="fm-data-item-line-label">Client Secret:</span>
                {
                  mdsCredential.clientSecret ? (
                    <span className="fm-data-item-line-value"> { mdsCredential.clientSecret } <span className="fm-data-item-line-warning">(Warning: This secret will disappear once you leave this page.)</span></span>
                  ) : (
                    <button className="fm-data-item-line-button" disabled={loading} onClick={this.generateMDSCredential}>Click to regenerate</button>
                  )
                }
              </p>
            )
          }
        </div>
      </div>
    );
  }

  renderItem(item, i) {
    return (
      <div className="fm-data-item" key={i}>
        <p className="fm-data-item-title">{ item.name.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }</p>
        <p className="fm-data-item-endpoint"><span className="fm-data-item-endpoint-method">GET</span><span className="fm-data-item-endpoint-url">{ item.url }</span></p>
        <p className="fm-data-item-description">{ item.description }</p>
      </div>
    );
  }

  renderGBFS(gbfs) {
    return (
      <div className="fm-data-section">
        <p className="fm-data-section-title">General Bikeshare Feed Specification</p>
        <p className="fm-data-section-text">The Flamingo API supports the General Bikeshare Feed Specification (GBFS), created by the North American Bike Share Association. The GBFS is the open data standard for bikeshare. GBFS makes real-time data feeds in a uniform format publicly available online, with an emphasis on findability. These endpoints are publicly available and do not require any authorisation.</p>
        <p className="fm-data-section-text">For more information on this specification, visit the <a href="https://github.com/NABSA/gbfs" target="_blank" rel="noopener noreferrer" className="fm-data-section-link">NABSA/gbfs</a> repository.</p>
        <div className="fm-data-items">{ gbfs.feeds.map(this.renderItem) }</div>
      </div>
    );
  }

  render() {
    const { loading, gbfs, mdsCredential, error } = this.state;

    return (
      <div className="fm-data">
        <NavigationBar title="Data Sharing" loading={loading} />
        { this.renderMDS(mdsCredential, loading) }
        { gbfs && this.renderGBFS(gbfs) }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DataSharing;
